<template>
  <div class="box">
    <transition name="loader" mode="out-in">
      <div
        class="d-flex mb-3 align-items-center justify-content-center filter-bar"
        v-if="(!sites_loading && _sites.length > 0) || filter.status">
        <div class="w-100 px-2">
          <input
            type="text"
            @input="Search()"
            v-model="filter.search"
            class="form-control arcadias-input"
            placeholder="Arama" />
        </div>
        <div class="w-100 px-2">
          <select class="form-control arcadias-input" v-model="filter.company">
            <option :value="null">Firma Seçiniz</option>
            <option
              :value="company._id"
              v-for="company of _companies"
              :key="company">
              {{ company.name }}
            </option>
          </select>
        </div>
        <div class="w-100 px-2">
          <select
            class="form-control arcadias-input"
            v-model="filter.site_type">
            <option :value="null">Site Tipi Seçiniz</option>
            <option
              :value="site_type._id"
              v-for="site_type of _site_types"
              :key="site_type">
              {{ site_type.name }}
            </option>
          </select>
        </div>
        <div class="px-2 d-flex justify-content-end gap-2">
          <button class="arcadias-btn-primary sm" @click="Filter()">
            <i class="bi bi-search"></i> Ara
          </button>
          <button class="arcadias-btn-danger sm" @click="ClearFilter()">
            <i class="bi bi-trash"></i> Temizle
          </button>
        </div>
      </div>
    </transition>
    <transition name="loader" mode="out-in">
      <table
        class="table"
        style="font-size: 13px"
        v-if="!sites_loading && _sites.length > 0">
        <thead>
          <tr>
            <th scope="col">
              IIS ID
              <i
                class="bi bi-sort-numeric-down sort-icon"
                @click="Sort('iis_id')"
                :class="{ active: sort.active == 'iis_id' }"
                v-if="sort.iis_id"></i>
              <i
                class="bi bi-sort-numeric-up sort-icon"
                @click="Sort('iis_id')"
                :class="{ active: sort.active == 'iis_id' }"
                v-else></i>
            </th>
            <th scope="col">
              Alan Adı
              <i
                class="bi bi-sort-alpha-down sort-icon"
                @click="Sort('domain')"
                :class="{ active: sort.active == 'domain' }"
                v-if="sort.domain"></i>
              <i
                class="bi bi-sort-alpha-up sort-icon"
                @click="Sort('domain')"
                :class="{ active: sort.active == 'domain' }"
                v-else></i>
            </th>
            <th scope="col">Firma</th>
            <th scope="col">Site Tipi</th>
            <th scope="col">Etkinlik Zamanı</th>
            <th scope="col">Ne Zaman Tanımlandı</th>
            <th scope="col">Ne Zaman Yayınlandı</th>
            <th scope="col">SSL Durumu</th>
            <th scope="col" style="width: 20px">Disk</th>
            <th scope="col">İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="site of _sites" :key="site">
            <th>{{ site.iis_id }}</th>
            <td>{{ site.domain }}</td>
            <td>{{ site.owner?.name || "-- --" }}</td>
            <td>{{ site.type?.name || "-- --" }}</td>
            <td>
              {{
                site.ets
                  ? new Date(site.ets).toLocaleDateString("tr-TR")
                  : "-- --"
              }},
              {{
                site.ete
                  ? new Date(site.ete).toLocaleDateString("tr-TR")
                  : "-- --"
              }}
            </td>
            <td>
              {{
                site.delivered_date
                  ? new Date(site.delivered_date).toLocaleDateString("tr-TR")
                  : "-- --"
              }}
            </td>
            <td>
              {{
                site.published_date
                  ? new Date(site.published_date).toLocaleDateString("tr-TR")
                  : "-- --"
              }}
            </td>
            <td>{{ site.ssl || "-- --" }}</td>
            <td>{{ site.disk || "-- --" }}</td>
            <td class="text-center">
              <router-link
                :to="{ name: 'UpdateSite', params: { id: site._id } }"
                class="arcadias-btn-primary me-2">
                <i class="bi bi-pencil-square"></i>
              </router-link>
              <a class="arcadias-btn-danger" @click="DeleteSite(site._id)">
                <i class="bi bi-trash"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else-if="!sites_loading && _sites.length == 0"
        class="alert alert-info">
        Henüz Site Bulamadım. Eklemek için sol menüden Site ekleye gidin.
      </div>
      <PageLoader v-else />
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PageLoader from "@/components/shared/PageLoader";

export default {
  name: "Home",
  components: {
    PageLoader,
  },
  computed: {
    ...mapGetters(["_sites", "_site_types", "_companies"]),
  },
  data() {
    return {
      sites_loading: true,
      sort: {
        active: "iis_id",
        domain: false,
        iis_id: true,
      },
      filter: {
        status: false,
        company: null,
        site_type: null,
        search: "",
      },
    };
  },
  mounted() {
    this.$store
      .dispatch("GET_SITES")
      .then(() => {
        this.$store.dispatch("GET_COMPANIES").catch(() => {});
        this.$store.dispatch("GET_SITE_TYPES").catch(() => {});
      })
      .catch(() => {})
      .finally(() => (this.sites_loading = false));
  },
  methods: {
    Sort(key) {
      this.sort.active = key;
      this.sort[key] = !this.sort[key];
      this.sites_loading = true;

      if (
        this.filter !=
        {
          status: false,
          company: null,
          site_type: null,
        }
      ) {
        this.filter.status = true;
      }

      this.$store
        .dispatch("GET_SITES", {
          sort: this.sort.active,
          sort_status: this.sort[this.sort.active] ? 1 : -1,
          company: this.filter.company,
          site_type: this.filter.site_type,
          search: this.filter.search,
        })
        .catch(() => {})
        .finally(() => (this.sites_loading = false));
    },
    Search() {
      if (this.filter.search.length >= 3) {
        this.Filter();
      } else if (this.filter.search == 0) {
        this.Filter();
      }
    },
    Filter() {
      this.sites_loading = true;
      this.filter.status = true;
      this.$store
        .dispatch("GET_SITES", {
          sort: this.sort.active,
          sort_status: this.sort[this.sort.active] ? 1 : -1,
          company: this.filter.company,
          site_type: this.filter.site_type,
          search: this.filter.search,
        })
        .catch(() => {})
        .finally(() => (this.sites_loading = false));
    },
    ClearFilter() {
      this.sites_loading = true;
      this.filter = {
        status: false,
        company: null,
        site_type: null,
        search: "",
      };
      this.$store
        .dispatch("GET_SITES")
        .catch(() => {})
        .finally(() => (this.sites_loading = false));
    },
    DeleteSite(id) {
      this.sites_loading = true;
      this.$store
        .dispatch("DELETE_SITE", id)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Site başarıyla silindi.",
          });
          this.$store.dispatch("GET_SITES").catch(() => {});
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            title: err?.message,
            html: `
                ${
                  err?.errors
                    ?.map(
                      (err) =>
                        `<div class="alert alert-${err.status}">${err.message}</div>`
                    )
                    .join("") ?? ""
                }
              `,
            toast: false,
            position: "",
          });
        })
        .finally(() => (this.sites_loading = false));
    },
  },
};
</script>
<style lang="scss">
.box {
  -webkit-box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  -moz-box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  border-radius: 1rem;
}

.filter-bar {
  -webkit-box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  -moz-box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  box-shadow: 0px 0px 5px 0px rgb(234, 234, 234);
  border-radius: 1rem;
  position: sticky;
  top: 90px;
  z-index: 999;
  border-radius: 1rem;
  background-color: white;
  // border: 1px solid #e2e2e2;
  padding: 15px;
}

.sort-icon {
  color: black;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;

  &:hover,
  &.active {
    background-color: #dedede;
  }
}
</style>
